import React, {useCallback, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Form, {ButtonItem, ButtonOptions, Item, Label, RequiredRule} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import {useAuth} from '../../contexts/auth';

import './LoginForm.scss';

export default function LoginForm() {
  const navigate = useNavigate();
  const {signIn} = useAuth();
  const [loading, setLoading] = useState(false);
  const formData = useRef({login: '', password: ''});

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    const {login, password} = formData.current;
    setLoading(true);

    const result = await signIn(login, password);
    if (!result.isOk) {
      setLoading(false);
      notify(result.message, 'error', 2000);
    }
  }, [signIn]);

  const onCreateAccountClick = useCallback(() => {
    navigate('/create-account');
  }, [navigate]);

  return (
    <form className={'login-form'} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={'login'}
          editorType={'dxTextBox'}
          editorOptions={loginEditorOptions}
        >
          <RequiredRule message="Login is required"/>
          <Label visible={false}/>
        </Item>
        <Item
          dataField={'password'}
          editorType={'dxTextBox'}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="Password is required"/>
          <Label visible={false}/>
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true}/>
                  : 'Sign In'
              }
            </span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
    </form>
  );
}

const loginEditorOptions = {stylingMode: 'filled', placeholder: 'Login'};
const passwordEditorOptions = {stylingMode: 'filled', placeholder: 'Password', mode: 'password'};
