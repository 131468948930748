import Button from 'devextreme-react/button';
import React from 'react';

const FormButtons = ({navigate, formSubmit, mode}) => {
  return (
    <div className={'button-container'}>
      <Button text={'Назад'} onClick={() => {
        navigate(-1);
      }}/>
      <Button
        disabled={mode === 'view'}
        type={'default'}
        text={'Отправить'}
        onClick={formSubmit}
      />
    </div>
  );
}

export default FormButtons;