import React from 'react';
import ScrollView from 'devextreme-react/scroll-view';
import {Header} from '../../components';

const AdminLayout = ({children}) => {
  return (
    <div style={{width: '100%'}} className={'container'}>
      <Header/>
      <div className={'dx-card admin-layout-wrapper'}>
        {children}
      </div>
    </div>
  );
}

export default AdminLayout;
