import {useNavigate, useParams} from 'react-router-dom';
import Button from 'devextreme-react/button';
import React, {useEffect, useRef, useState} from 'react';
import notify from 'devextreme/ui/notify';
import {apiRequest} from 'api/async';
import Form, {SimpleItem} from 'devextreme-react/form';
import LoadPanel from 'devextreme-react/load-panel';
import {formDefinitions, vars} from 'utils/variables';
import {SelectBox, TextBox} from 'devextreme-react';
import useLocalStorage from 'hooks/useLocalStorage';
import FormButtons from 'components/form/form-buttons';

const {APP_CODES: {SUCCESS}, PAYOUT_TYPE_ID} = vars;

const dataFields = [
  'ExternalIssueID',
  'ExternalAccount',
  'ExternalClientName',
  'Amount',
  'CurrencyID',
  'FeeAmount',
  'TotalAmount',
  'PaymentSystemID',
  'ClientAccount',
  'PsClientName',
  'ClientCode',
  'Description'
];

const PaymentForm = ({mode}) => {
  const formRef = useRef(null);
  const {paymentId} = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState(null);
  const [paymentSystems, setPaymentSystems] = useState([]);
  const [paymentSystemId, setPaymentSystemId] = useState(null);
  const [currencies, setCurrencies] = useState([]);

  const fetchPaymentData = async () => {
    const objectName = 'Payment';
    const request = await apiRequest({
      operation: `${objectName}/List`,
      data: {
        Columns: dataFields,
        Filters: {
          ID: paymentId,
        }
      }
    });

    if (request.data.ResponseCode === SUCCESS) {
      return request.data.Response[objectName][0];
    } else {
      throw new Error(request.data.ResponseText);
    }
  }

  const fetchPaymentSystems = async () => {
    const objectName = 'PaymentSystem';
    const request = await apiRequest({
      operation: `${objectName}/List`,
      data: {
        Page: -1,
      }
    });

    if (request.data.ResponseCode === SUCCESS) {
      return request.data.Response[objectName];
    } else {
      throw new Error(request.data.ResponseText);
    }
  }

  const fetchCurrencies = async () => {
    const objectName = 'Currency';
    const request = await apiRequest({
      operation: `${objectName}/List`,
      data: {
        Page: -1,
      }
    });

    if (request.data.ResponseCode === SUCCESS) {
      return request.data.Response[objectName];
    } else {
      throw new Error(request.data.ResponseText);
    }
  }

  useEffect(() => {
    if (paymentSystems.length === 0) {
      fetchPaymentSystems().then((response) => {
        setPaymentSystems(response);
      }).catch((error) => {
        notify(error, 'error', 3000);
      });
    }

    if (currencies.length === 0) {
      fetchCurrencies().then((response) => {
        setCurrencies(response);
      }).catch((error) => {
        notify(error, 'error', 3000);
      });
    }

    if (paymentId && !formData) {
      fetchPaymentData().then((response) => {
        setFormData(response);
      }).catch((error) => {
        notify(error, 'error', 3000);
      });
    }
  }, []);

  const getPageTitle = () => {
    switch (mode) {
      case 'create':
        return 'Создание платежа';
      case 'view':
        return 'Просмотр платежа';
      case 'template':
        return 'Платеж по шаблону';
      default:
        return '';
    }
  }

  const generateRandomValue = () => {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';

    for (let i = 0; i < 32; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return result;
  };

  const renderRandomFieldWithButton = (data) => {
    return (
      <div style={{display: 'flex', alignItems: 'center'}}>
        <TextBox
          value={data.editorOptions.value}
          onValueChanged={data.component.option('onFieldDataChanged')}
          readOnly={mode === 'view'}
          style={{flexGrow: 1}}
        />
        <Button
          icon='refresh'
          onClick={() => {
            const randomValue = generateRandomValue();
            setFormData({...formData, [data.dataField]: randomValue});
          }}
          disabled={mode === 'view'}
          style={{marginLeft: '10px'}}
        />
      </div>
    );
  };


  const onPaymentSystemChanged = async ({value}) => {
    setFormData({...formData, PaymentSystemID: value});
    setPaymentSystemId(value);
  }

  const onAmountChanged = async ({value}) => {
    try {
      const calculation = await fetchAmountFee(value);

      if (formRef.current) {
        const form = formRef.current.instance();
        form.updateData('FeeAmount', calculation.FeeAmount);
        form.updateData('TotalAmount', calculation.TotalAmount);
      }

    } catch (error) {
      notify(error, 'error', 3000);
    }
  }

  const fetchAmountFee = async (value) => {
    const request = await apiRequest({
      operation: 'PaymentFee/Calculate',
      data: {
        Request: {
          Amount: value,
          PaymentSystemID: formData.PaymentSystemID,
          PaymentTypeID: PAYOUT_TYPE_ID,
        }
      }
    });

    if (request.data.ResponseCode === SUCCESS) {
      return request.data.Response;
    } else {
      throw new Error(request.data.ResponseText);
    }
  }

  useEffect(() => {
    if (paymentSystemId && formData.Amount) {
      fetchAmountFee(formData.Amount).then((calculation) => {
        setFormData({
          ...formData,
          FeeAmount: calculation.FeeAmount,
          TotalAmount: calculation.TotalAmount,
        });
      }).catch((error) => {
        notify(error, 'error', 3000);
      });
    }
  }, [paymentSystemId]);

  const formSubmit = async () => {
    if (!formData) return;

    const requestData = {};
    dataFields.forEach(key => {
      if (formData.hasOwnProperty(key)) {
        requestData[key] = formData[key];
      }
    });

    requestData.PaymentTypeID = PAYOUT_TYPE_ID; // always

    const request = await apiRequest({
      operation: 'Payment/Ins',
      data: {
        Request: {
          Payment: [requestData],
        }
      }
    });

    if (request.data.ResponseCode === SUCCESS) {
      notify('Saved', 'success', 3000);
      navigate(-1);
    } else {
      notify(request.data.ResponseText, 'error', 3000);
    }
  }

  const [colCount, setColCount] = useLocalStorage('preferredColCount', 1);

  const colCountOptions = [1, 2];

  const handleColCountChange = (e) => {
    setColCount(e.value);
  };

  if (mode !== 'create' && !formData) {
    return <LoadPanel visible={true}/>;
  }

  return (
    <div className={'payment-form'}>
      <h4 className={'title'}>{getPageTitle()}</h4>
      <div className={'form-toolbar'}>
        <FormButtons
          navigate={navigate}
          formSubmit={formSubmit}
          mode={mode}
        />
        <div className={'form-settings'}>
          <span>Кол-во колонок:</span>
          <SelectBox
            width={60}
            items={colCountOptions}
            value={colCount}
            onValueChanged={handleColCountChange}
          />
        </div>
      </div>
      <div className={'form-container'}>
        <Form
          id='form'
          ref={formRef}
          labelMode={'outside'}
          formData={formData}
          readOnly={mode === 'view'}
          showColonAfterLabel={true}
          labelLocation={'left'}
          minColWidth={200}
          colCount={colCount}
          width={'100%'}
        >
          <SimpleItem
            dataField={'PaymentTypeName'}
            label={{text: formDefinitions.PaymentTypeName}}
            editorOptions={{
              value: 'Выплата',
              readOnly: true,
            }}
          />
          <SimpleItem
            dataField={'PaymentSystemID'}
            label={{text: formDefinitions.PaymentSystemName}}
            editorType='dxSelectBox'
            editorOptions={{
              dataSource: paymentSystems,
              displayExpr: 'Name',
              valueExpr: 'ID',
              onValueChanged: onPaymentSystemChanged,
            }}
          />
          <SimpleItem
            dataField={'ExternalIssueID'}
            label={{text: formDefinitions.ExternalIssueID}}
            render={renderRandomFieldWithButton}
          />
          <SimpleItem dataField={'ExternalAccount'} label={{text: formDefinitions.ExternalAccount}}/>
          <SimpleItem dataField={'ExternalClientName'} label={{text: formDefinitions.ExternalClientName}}/>
          <SimpleItem dataField={'ClientAccount'} label={{text: formDefinitions.ClientAccount}}/>
          <SimpleItem dataField={'PsClientName'} label={{text: formDefinitions.PsClientName}}/>
          <SimpleItem dataField={'ClientCode'} label={{text: formDefinitions.ClientCode}}/>
          <SimpleItem dataField={'Description'} label={{text: formDefinitions.Description}}/>
          <SimpleItem
            dataField={'CurrencyID'}
            label={{text: formDefinitions.CurrencyID}}
            editorType='dxSelectBox'
            editorOptions={{
              dataSource: currencies,
              displayExpr: 'Name',
              valueExpr: 'ID',
              onValueChanged: (e) => {

              }
            }}
          />
          <SimpleItem
            dataField={'Amount'}
            label={{text: formDefinitions.Amount}}
            editorOptions={{
              onValueChanged: onAmountChanged
            }}
          />
          <SimpleItem
            dataField={'FeeAmount'}
            label={{text: formDefinitions.FeeAmount}}
            editorOptions={{
              readOnly: true,
            }}
          />
          <SimpleItem
            dataField={'TotalAmount'}
            label={{text: formDefinitions.TotalAmount}}
            editorOptions={{
              readOnly: true,
            }}
          />
        </Form>
      </div>
      <div className={'buttons-bottom padded'}>
        <FormButtons
          navigate={navigate}
          formSubmit={formSubmit}
          mode={mode}
        />
      </div>
    </div>
  );
}

export default PaymentForm;