import axios from 'axios';
import {vars} from 'utils/variables';

const {APP_CODES} = vars;

export const baseUrl = process.env.REACT_APP_WAPI;

const makeUrl = operation => `${baseUrl}/${operation}`;

export const prepareRequestOptions = ({method = 'POST', operation, data = {}}) => {
  return {
    method,
    url: makeUrl(operation),
    data,
    validateStatus: function (code) {
      return code < 500;
    }
  }
};

const deAuth = () => {
  window.localStorage.removeItem('user');
  window.location.replace("/");
};

axios.interceptors.response.use(function (response) {
  if (response.data.ResponseCode === '401') {
    deAuth();
  }
  return response;
}, function (error) {
  if (error.status === 401) {
    deAuth();
  }
  return Promise.reject(error);
});

export const apiRequest = async (options) => {
  try {
    return await axios(prepareRequestOptions(options));
  } catch (error) {
    return error;
  }
};