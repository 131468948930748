import {Navigate, Route, Routes} from 'react-router-dom';
import routes from './app-routes';
import AdminLayout from './layouts/admin/admin';
import PaymentForm from './pages/payments/payment-form';

export default function Content() {
  return (
    <AdminLayout>
      <Routes>
        {routes.map(({path, element}) => (
          <Route
            key={path}
            path={path}
            element={element}
          />
        ))}
        <Route path='/payment/create' element={<PaymentForm mode='create'/>}/>
        <Route path='/payment/view/:paymentId' element={<PaymentForm mode='view'/>}/>
        <Route path='/payment/template/:paymentId' element={<PaymentForm mode='template'/>}/>
        <Route
          path='*'
          element={<Navigate to='/payments'/>}
        />
      </Routes>
    </AdminLayout>
  );
}

