import React, {useEffect, useMemo, useRef, useState} from 'react';
import {columnDefinitions, paymentOptions, vars} from 'utils/variables';
import DataGrid, {
  Column,
  FilterRow,
  Item,
  Paging,
  RemoteOperations,
  Scrolling,
  Selection,
  Toolbar,
} from 'devextreme-react/data-grid';
import {getDataSource} from 'api/dataSource';
import Button from 'devextreme-react/button';
import {useNavigate} from 'react-router-dom';
import {Popup} from 'devextreme-react';
import {confirm} from 'devextreme/ui/dialog';
import {apiRequest} from 'api/async';
import './payments.scss';
import notify from 'devextreme/ui/notify';

const {
  APP_CODES: {SUCCESS},
  PAYMENT_STATUS_ID_WAITING,
  PAYOUT_TYPE_ID,
  INCOME_TYPE_ID,
  REFUND_SUB_TYPE_ID,
  CREDIT_SUB_TYPE_ID,
  OPERATION_CONFIRM,
  OPERATION_CANCEL,
} = vars;

const Payments = () => {
  const navigate = useNavigate();
  const [selectedPayment, setSelectedPayment] = useState(null);
  const paymentGridRef = useRef(null);
  const [isVisiblePopup, setIsVisiblePopup] = useState(false);
  const refreshGrid = () => {
    if (paymentGridRef.current) {
      paymentGridRef.current.instance().refresh();
    }

  }

  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (selectedPayment) {
      console.log(selectedPayment);
      setIsDisabled(selectedPayment.PaymentStatusID !== PAYMENT_STATUS_ID_WAITING);
    }
  }, [selectedPayment]);

  const onSelectionChanged = ({selectedRowsData}) => {
    const [extractedData = {}] = selectedRowsData;
    setSelectedPayment(extractedData);
  };

  const dataSource = useMemo(() => getDataSource(paymentOptions), []);

  const cancelPayment = () => {
    actionWithConfirmDialog(OPERATION_CANCEL);
  };

  const confirmPayment = () => {
    if (selectedPayment.PaymentTypeID === PAYOUT_TYPE_ID) {
      setIsVisiblePopup(true);
    } else if (selectedPayment.PaymentTypeID === INCOME_TYPE_ID) {
      actionWithConfirmDialog(OPERATION_CONFIRM);
    }
  }

  const actionWithConfirmDialog = (operation) => {
    const result = confirm('<i>Вы уверены?</i>', 'Подтерждение действий');

    result.then(async (dialogResult) => {
      if (dialogResult) {
        paymentRequest(operation).catch();
      }
    });
  }

  const paymentRefundConfirm = () => {
    paymentRequest(OPERATION_CONFIRM, REFUND_SUB_TYPE_ID).finally(() => {
      setIsVisiblePopup(false);
    });
  }

  const paymentCreditConfirm = () => {
    paymentRequest(OPERATION_CONFIRM, CREDIT_SUB_TYPE_ID).finally(() => {
      setIsVisiblePopup(false);
    });
  }

  const recheckStatus = async () => {
    const request = await apiRequest({
      operation: 'Payment/RecheckStatus',
      data: {
        Params: {
          PaymentID: selectedPayment.ID,
        }
      }
    });

    if (request.data.ResponseCode === SUCCESS && paymentGridRef.current) {
      paymentGridRef.current.instance().refresh();
    } else {
      notify(request.data.ResponseText, 'error', 2000);
    }
  }

  const paymentRequest = async (operation, subType) => {
    try {
      const params = {
        PaymentID: selectedPayment.ID,
      };

      if (subType !== undefined && subType !== null) {
        params.PaymentSubTypeID = subType;
      }

      const cancelRequest = await apiRequest({
        operation: `Payment/${operation}`,
        data: {
          Params: params,
        },
      });

      if (cancelRequest.data.ResponseCode === SUCCESS && paymentGridRef.current) {
        paymentGridRef.current.instance().refresh().catch((error) => {
          notify(error, 'error', 3000);
        });
      } else {
        notify(cancelRequest.data.ResponseText, 'error', 3000);
      }
    } finally {
      if (paymentGridRef.current) {
        paymentGridRef.current.instance().deselectAll();
      }
    }
  }

  return (
    <React.Fragment>
      <h4 className={'content-block grid-title'}>Платежи</h4>
      <div className={'content-block grid-wrapper'}>
        <Popup
          visible={isVisiblePopup}
          maxWidth={450}
          maxHeight={110}
          title={'Подтведить платеж'}
          onHiding={() => {
            setIsVisiblePopup(false);
          }}
          showCloseButton
        >
          <div className={'buttons-bottom'}>
            <div className={'button-container'}>
              <Button onClick={paymentRefundConfirm}>Выплата через возвраты</Button>
              <Button onClick={paymentCreditConfirm}>Credit</Button>
            </div>
          </div>
        </Popup>
        <DataGrid
          ref={paymentGridRef}
          //dataSource={payment}
          dataSource={dataSource}
          keyExpr={'ID'}
          allowColumnReordering={false}
          allowColumnResizing={false}
          hoverStateEnabled={true}
          height={'100%'}
          width={'100%'}
          wordWrapEnabled={true}
          columnMinWidth={150}
          onSelectionChanged={onSelectionChanged}
          columnAutoWidth={false}
        >
          <Toolbar>
            <Item location={'before'}>
              <Button text={'Создать платеж'} onClick={() => {
                navigate('/payment/create');
              }}/>
            </Item>
            <Item location={'before'}>
              <Button disabled={!selectedPayment} text={'Создать по шаблону'} onClick={() => {
                navigate(`/payment/template/${selectedPayment.ID}`);
              }}/>
            </Item>
            <Item location={'before'}>
              <Button disabled={!selectedPayment} text={'Просмотреть'} onClick={() => {
                navigate(`/payment/view/${selectedPayment.ID}`);
              }}/>
            </Item>
            <Item location={'before'}>
              <Button text={'Обновить'} onClick={refreshGrid}/>
            </Item>
            <Item location={'before'}>
              <Button disabled={isDisabled} text={'Подтвердить'} onClick={confirmPayment}/>
            </Item>
            <Item location={'before'}>
              <Button disabled={isDisabled} text={'Отменить'} onClick={cancelPayment}/>
            </Item>
            <Item location={'before'}>
              <Button disabled={!selectedPayment} text={'Статус'} onClick={recheckStatus}/>
            </Item>
          </Toolbar>
          <RemoteOperations
            paging
            filtering
          />
          <Paging enabled defaultPageSize={50}/>
          <Scrolling
            mode={'infinite'}
            showScrollbar='onHover'
          />
          <Selection
            mode='single'
            showCheckBoxesMode='always'
          />
          <FilterRow visible={true}/>
          {Object.entries(columnDefinitions).map(([dataField, option]) => {
            let dateProps = {};

            if (dataField === 'InsDate' || dataField === 'LastUpdate') {
              dateProps = {
                dataType: 'date',
                format: 'dd.MM.yy, HH:mm',
              };
            }

            const caption = Array.isArray(option) ? option[0] : option;

            return (
              <Column
                key={dataField}
                dataField={dataField}
                caption={caption}
                allowSorting={false}
                width={Array.isArray(option) ? option[1] : 'auto'}
                {...dateProps}
              />
            );
          })}
        </DataGrid>
      </div>
    </React.Fragment>
  );
}

export default Payments;