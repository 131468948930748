export const vars = {
  APP_CODES: {
    SUCCESS: '000',
  },
  PAYMENT_STATUS_ID_WAITING: 8,
  PAYOUT_TYPE_ID: 1,
  INCOME_TYPE_ID: 2,
  REFUND_SUB_TYPE_ID: 0,
  CREDIT_SUB_TYPE_ID: 1,
  OPERATION_CONFIRM: 'Confirm',
  OPERATION_CANCEL: 'Cancel',
}

export const paymentOptions = {
  key: 'ID',
  object: 'Payment',
  columns: [
    'ID', 'InsDate', 'ConfirmType', 'PaymentTypeName', 'PaymentTypeID', 'ExternalIssueID', 'ExternalAccount',
    'ExternalClientName', 'CurrencyName', 'Amount', 'FeeAmount', 'TotalAmount',
    'PaymentSystemName', 'ClientAccount', 'PsClientName', 'Description', 'LastUpdate',
    'PaymentStatusName', 'ErrorCode', 'ErrorText', 'ProcessCount', 'PsPaymentStatus', 'PsStatusProcessCount',
    'InsUserName', 'PaymentStatusID', 'Urlid',
  ],
  fieldsSettings: {
    InsDate: {type: 'dateTime'},
    LastUpdate: {type: 'dateTime'},
    ID: {type: 'number'},
    PaymentStatusName: {type: 'string'},
    Amount: {type: 'number'},
    FeeAmount: {type: 'number'},
    TotalAmount: {type: 'number'},
  },
  sorts: '-InsDate',
};

export const columnDefinitions = {
  ID: 'Номер',
  Urlid: 'ID',
  InsDate: 'Дата создания',
  PaymentTypeName: 'Тип платежа',
  ExternalIssueID: ['Внешний ID', 300],
  ExternalAccount: ['Счет Клиента', 300],
  ExternalClientName: 'Имя Клиента',
  Amount: 'Сумма',
  CurrencyName: 'Валюта',
  FeeAmount: 'Комиссия',
  TotalAmount: 'Сумма к зачислению',
  PaymentSystemName: 'Платежная система',
  ClientAccount: 'Счет Контрагента',
  PsClientName: 'Имя Контрагента',
  Description: 'Назначение платежа',
  LastUpdate: 'Дата последней обработки операции',
  PaymentStatusID: 'Статус платежа',
  PaymentStatusName: 'Статус платежа',
  ErrorCode: 'Код последней ошибки',
  ErrorText: 'Текст последней ошибки',
  ProcessCount: 'Кол-во обработок',
  PsPaymentStatus: 'Статус транзакции',
  PsStatusProcessCount: 'Кол-во попыток получения статуса',
  InsUserName: 'Создатель'
};

export const formDefinitions = {
  PaymentTypeName: 'Тип платежа*',
  ExternalIssueID: 'Внешний ID',
  ExternalAccount: 'Счет Клиента',
  ExternalClientName: 'Имя Клиента',
  Amount: 'Сумма*',
  CurrencyID: 'Валюта*',
  FeeAmount: 'Комиссия',
  TotalAmount: 'Сумма к зачислению',
  PaymentSystemName: 'Платежная система*',
  ClientAccount: 'Счет Контрагента*',
  PsClientName: 'Имя Контрагента',
  ClientCode: 'Код Контрагента',
  Description: 'Назначение платежа',
  LastUpdate: 'Дата последней обработки операции',
  PaymentStatusID: 'Статус платежа',
  PaymentStatusName: 'Статус платежа',
  ErrorCode: 'Код последней ошибки',
  ErrorText: 'Текст последней ошибки',
  ProcessCount: 'Кол-во обработок',
  PsPaymentStatus: 'Статус транзакции',
  PsStatusProcessCount: 'Кол-во попыток получения статуса',
  InsUserName: 'Создатель'
};