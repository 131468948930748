import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { apiRequest } from '../api/async';
import md5 from 'crypto-js/md5';
import { vars } from 'utils/variables';
import {useNavigate} from 'react-router';

const { APP_CODES } = vars;

function AuthProvider(props) {
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function () {
      const storedUser = localStorage.getItem('user');
      if (storedUser) {
        setUser(JSON.parse(storedUser));
      } /*else {
        navigate('/login');
      }*/
      setLoading(false);
    })();
  }, []);

  const signIn = useCallback(async (login, password) => {
    try {
      const md5HashedPassword = md5(password).toString();

      const loginRequest = await apiRequest({
        operation: 'Login',
        data: {
          Login: login,
          Password: md5HashedPassword,
        }
      });

      if (loginRequest.data) {
        const { ResponseCode, Response, ResponseText } = loginRequest.data;

        if (ResponseCode === APP_CODES.SUCCESS) {
          if (Response.IsPasswordExpired) {
            return { isOk: false, data: null, message: 'Password expired' };
          } else {
            setUser(Response);
            localStorage.setItem('user', JSON.stringify(Response));
            return { isOk: true, data: Response };
          }
        } else {
          return { isOk: false, data: null, message: ResponseText };
        }
      } else {
        return { isOk: false, data: null, message: 'Network Error' };
      }
    } catch (error) {
      console.error('Login error:', error);
      return { isOk: false, data: null, message: 'An error occurred during login' };
    }
  }, []);

  const signOut = useCallback(async () => {
    setUser(undefined);
    localStorage.removeItem('user');
    await apiRequest({
      operation: 'Logout',
    });
  }, []);

  return (
    <AuthContext.Provider value={{ user, signIn, signOut, loading }} {...props} />
  );
}

const AuthContext = createContext({ loading: false });
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }