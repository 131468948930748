import axios from 'axios';
import DataSource from 'devextreme/data/data_source';
import {prepareRequestOptions} from './async';
import notify from 'devextreme/ui/notify';
import {getFilter} from './dataSourceFilterUtils';
import {vars} from 'utils/variables';

const {APP_CODES: {SUCCESS}} = vars;

/**
 * @param {string} key
 * @param {string[]} columns
 * @param {string} object
 * @param {object} defFilter
 * @param {object} [fieldsSettings]
 * @param {string} sorts
 * @param {function} modify
 * @return {DataSource}
 */
export const getDataSource = ({
																key,
																columns = undefined,
																object,
																defFilter = {},
																fieldsSettings,
																sorts = '-ID',
																modifier = null,
																insertKeys = [],
															}) => {
	const dataSource = new DataSource(
		{
			key,
			load: async ({isLoadingAll, take, skip, filter}) => {
				let page = (skip + take) / take || -1;
				let apiFilter = fieldsSettings ? getFilter(filter, fieldsSettings) || {} : {};
				
				let requestObj = {
					Filters: {
						...defFilter,
						...apiFilter,
					},
					Sorts: [sorts],
					Page: isLoadingAll ? -1 : page,
					RowsPerPage: take,
					Columns: columns,
				};
				
				if (!Object.keys(requestObj.Filters).length) {
					delete requestObj.Filters;
				}
				
				const response = await axios(prepareRequestOptions({
					operation: `${object}/List`,
					data: requestObj,
				}));
				
				if (response.data.ResponseCode === SUCCESS) {
					const result = response.data.Response[object];
					return modifier ? modifier(result) : result;
				}
				
				notify(response.data.ResponseText, 'error', 2000);
				return [];
			},
		},
	);
	
	return dataSource;
};